<template>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-4">
        <h2 class="text-center fs-1 fw-bold text-primary">
          {{ title }}
        </h2>
        <p class="text-center m-auto col-lg-6 col-md-10 col-12">
          {{ subTitle }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleHome",
  props: ["title", "subTitle"],
};
</script>

<style lang="scss" scoped>
.text-primary {
  color: var(--text-300) !important;
}
</style>
