<template>
  <div
    class="spinner-border spinner-border-lg my-5 m-auto d-flex"
    v-if="isLoadingPlans"
  ></div>
  <swiper
    :effect="'coverflow'"
    :grabCursor="true"
    :centeredSlides="true"
    :slidesPerView="'auto'"
    :coverflowEffect="{
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    }"
    :initialSlide="1"
    :pagination="true"
    :modules="modules"
    class="mySwiper"
    v-else
  >
    <swiper-slide v-for="(plan, index) in plans" :key="index">
      <a
        href="javascript:void(0)"
        @click="selectPlan(plan.id)"
        :aria-label="plan.name"
        :title="plan.name"
      >
        <img
          :src="plan.thumbnail"
          class="card-img-top"
          :alt="plan.name"
          :title="plan.name"
        />
      </a>
    </swiper-slide>
  </swiper>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination } from "swiper/modules";

import axios from "axios";

export default {
  name: "PackagesThree",
  data() {
    return {
      plans: [],
      isLoadingPlans: true,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [EffectCoverflow, Pagination],
    };
  },
  methods: {
    async getPlans() {
      await axios
        .get("/plans")
        .then((response) => {
          if (response.status == 200) {
            this.plans = response.data.data[0];
            this.isLoadingPlans = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectPlan(id) {
      this.$emit("plan-selected", id);
    },
  },
  mounted() {
    this.getPlans();
  },
};
</script>

<style scoped>
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 165px;
  border-radius: 50px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
