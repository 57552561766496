import Vuex from "vuex";
import auth from "./auth.min.js";
import settings from "./settings";
import axios from "axios";

export default new Vuex.Store({
  state: {
    lat: 0,
    lng: 0,
    address: "",
    generalSettings: {},
  },
  mutations: {
    updateAddress(state, address) {
      state.address = address;
    },

    setGeneralSettings(state, settings) {
      state.generalSettings = settings;
    },

    updateLatLng(state, { lat, lng }) {
      state.lat = lat;
      state.lng = lng;
    },

    clearAddress(state) {
      state.address = "";
      state.lat = 0;
      state.lng = 0;
    },
  },
  actions: {
    async fetchGeneralSettings({ commit }) {
      try {
        const response = await axios.get("/general/settings");
        if (response.status === 200) {
          const settings = response.data.data[0];

          commit("setGeneralSettings", settings);

          window.sessionStorage.setItem(
            "general_settings",
            JSON.stringify(settings)
          );
        }
      } catch (err) {
        console.error("Failed to fetch general settings:", err);
      }
    },
  },
  modules: {
    auth,
    settings,
  },
  // Ensure settings are loaded from session storage when store is initialized
  created() {
    const storedSettings = window.sessionStorage.getItem("general_settings");
    if (storedSettings) {
      this.commit("setGeneralSettings", JSON.parse(storedSettings));
    }
  },
});
