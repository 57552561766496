<template>
  <header class="py-2">
    <div class="container">
      <div class="row">
        <div class="d-flex align-items-center justify-content-between">
          <!-- logo -->
          <a href="/" title="لوجو ثقة العربية" aria-label="logo">
            <img
              src="/images/header_logo.webp"
              alt="لوجو ثقة العربية"
              class="logo"
              width="250"
              height="60"
              title="لوجو ثقة العربية"
            />
          </a>
          <!-- nav -->
          <nav class="d-none d-xl-flex">
            <ul class="list-unstyled d-flex gap-3 m-0 align-items-center">
              <li>
                <RouterLink
                  to="/"
                  exact-active-class="active_menu"
                  :aria-label="$t('Home')"
                  :title="$t('Home')"
                >
                  <i class="bi bi-house-door"></i>
                  {{ $t("Home") }}
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  to="/about"
                  exact-active-class="active_menu"
                  :aria-label="$t('About')"
                  :title="$t('About')"
                >
                  <!-- icon (About) -->
                  <i class="bi bi-info-circle"></i>
                  {{ $t("About") }}
                </RouterLink>
              </li>
              <!-- <li>
                                <RouterLink to="/serviceProvider" exact-active-class="active_menu">
                                    <i class="bi bi-people"></i>
                                    {{ $t('serviceProvider') }}
                                </RouterLink>
                            </li> -->
              <li>
                <RouterLink
                  to="/our-network"
                  exact-active-class="active_menu"
                  :aria-label="$t('OurNetwork')"
                  :title="$t('OurNetwork')"
                >
                  <!-- icon (OurNetwork) -->
                  <i class="bi bi-diagram-3"></i>
                  {{ $t("OurNetwork") }}
                </RouterLink>
              </li>
              <!-- <li>
                <RouterLink to="/MobApp" exact-active-class="active_menu">
                  <i class="bi bi-phone"></i>
                  {{ $t("MobApp") }}
                </RouterLink>
              </li> -->
              <li>
                <RouterLink
                  to="/blogs"
                  exact-active-class="active_menu"
                  :aria-label="$t('blogs')"
                  :title="$t('blogs')"
                >
                  <!-- icon (blogs) -->
                  <i class="bi bi-journal-text"></i>
                  {{ $t("blogs") }}
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  to="/contact-us"
                  exact-active-class="active_menu"
                  :aria-label="$t('ContactUs')"
                  :title="$t('ContactUs')"
                >
                  <!-- icon (ContactUs) -->
                  <i class="bi bi-envelope"></i>
                  {{ $t("ContactUs") }}
                </RouterLink>
              </li>
              <li>
                <n-dropdown
                  :options="languageOptions"
                  trigger="hover"
                  @select="changeLanguage"
                >
                  <a
                    href="#"
                    class="d-flex align-items-center gap-1"
                    :aria-label="$t('Language')"
                    :title="$t('Language')"
                  >
                    <!-- icon (Language) -->
                    <i class="bi bi-globe"></i>
                    {{ $t("Language") }} ({{ $i18n.locale }})
                    <i class="bi bi-chevron-down ms-2"></i>
                  </a>
                </n-dropdown>
              </li>
            </ul>
          </nav>
          <nav class="d-none d-xl-flex">
            <ul class="list-unstyled d-flex gap-3 m-0 align-items-center">
              <li v-if="authenticated">
                <RouterLink to="/my-account" aria-label="My Account" title="My Account">
                  <img
                    :src="user.image"
                    :alt="user.name"
                    class="rounded-circle"
                    width="40"
                    height="40"
                    v-if="user && user.image"
                  />
                  <img
                    src="../assets/images/profile.jpg"
                    alt="Profile Image"
                    class="rounded-circle"
                    width="40"
                    height="40"
                    v-else
                  />
                </RouterLink>
              </li>
              <li v-if="!authenticated">
                <RouterLink
                  to="/login"
                  class="btn btn-outline-light"
                  aria-label="login"
                  title="login"
                >
                  <!-- icon -->
                  <i class="bi bi-person"></i>
                  {{ $t("login") }}
                </RouterLink>
              </li>
            </ul>
          </nav>
          <!-- menu Bar -->
          <button
            class="btn btn-primary d-flex d-xl-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#menu_bar"
            aria-controls="menu_bar"
            name="menu_bar button"
            title="Menu Icon"
          >
            <i class="bi bi-list"></i>
          </button>
          <div
            class="offcanvas"
            :class="$i18n.locale == 'ar' ? 'offcanvas-start' : 'offcanvas-end'"
            data-bs-scroll="true"
            tabindex="-1"
            id="menu_bar"
            aria-labelledby="Enable both scrolling & backdrop"
          >
            <div class="offcanvas-header d-flex justify-content-between border-bottom">
              <h5 class="offcanvas-title" id="Enable both scrolling & backdrop">
                {{ $t("Menu") }}
              </h5>
              <ul class="list-unstyled m-0" v-if="authenticated">
                <li>
                  <RouterLink
                    to="/my-account"
                    class="d-flex gap-2 align-items-center"
                    aria-label="My Account"
                    title="My Account"
                  >
                    <img
                      :src="user.image"
                      :alt="user.name"
                      class="rounded-circle"
                      width="40"
                      height="40"
                      v-if="user && user.image"
                    />
                    <img
                      src="../assets/images/profile.jpg"
                      alt="Profile Image"
                      class="rounded-circle"
                      width="40"
                      height="40"
                      v-else
                    />
                  </RouterLink>
                </li>
              </ul>
              <button
                type="button"
                class="btn-close m-0"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                name="Close Button"
                title="Close Button"
              ></button>
            </div>
            <div class="offcanvas-body">
              <ul class="list-unstyled d-flex flex-column gap-4 m-0">
                <li>
                  <RouterLink to="/" exact-active-class="active_menu_mob" class="fs-5">
                    <i class="bi bi-house-door"></i>
                    {{ $t("Home") }}
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    to="/about"
                    exact-active-class="active_menu_mob"
                    class="fs-5"
                  >
                    <!-- icon (About) -->
                    <i class="bi bi-info-circle"></i>
                    {{ $t("About") }}
                  </RouterLink>
                </li>
                <!-- <li>
                                    <RouterLink to="/serviceProvider" exact-active-class="active_menu_mob" class="fs-5" >
                                        <i class="bi bi-people"></i>
                                        {{ $t('serviceProvider') }}
                                    </RouterLink>
                                </li> -->
                <li>
                  <RouterLink
                    to="/our-network"
                    exact-active-class="active_menu_mob"
                    class="fs-5"
                  >
                    <!-- icon (OurNetwork) -->
                    <i class="bi bi-diagram-3"></i>
                    {{ $t("OurNetwork") }}
                  </RouterLink>
                </li>
                <!-- <li>
                  <RouterLink
                    to="/MobApp"
                    exact-active-class="active_menu_mob"
                    class="fs-5"
                  >
        
                    <i class="bi bi-phone"></i>
                    {{ $t("MobApp") }}
                  </RouterLink>
                </li> -->
                <li>
                  <RouterLink
                    to="/blogs"
                    exact-active-class="active_menu_mob"
                    class="fs-5"
                  >
                    <!-- icon (blogs) -->
                    <i class="bi bi-journal-text"></i>
                    {{ $t("blogs") }}
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    to="/contact-us"
                    exact-active-class="active_menu_mob"
                    class="fs-5"
                  >
                    <!-- icon (ContactUs) -->
                    <i class="bi bi-envelope"></i>
                    {{ $t("ContactUs") }}
                  </RouterLink>
                </li>
                <li v-if="!authenticated">
                  <RouterLink
                    to="/login"
                    exact-active-class="active_menu_mob"
                    class="fs-5"
                  >
                    <!-- icon -->
                    <i class="bi bi-person"></i>
                    {{ $t("login") }}
                  </RouterLink>
                </li>
                <li>
                  <a href="#" @click="toggleLanguage" class="fs-5">
                    <!-- icon (Language) -->
                    <i class="bi bi-globe"></i>
                    {{ $t("Language") }}
                    ({{ $i18n.locale }})
                  </a>
                </li>
                <!-- Log Out -->
                <li v-if="authenticated">
                  <RouterLink to="#" class="fs-5" @click.prevent="signOut">
                    <!-- icon -->
                    <i class="bi bi-box-arrow-right"></i>
                    {{ $t("LogOut") }}
                  </RouterLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { NDropdown } from "naive-ui";

export default {
  name: "HeaderTop",
  data() {
    return {
      languageOptions: [
        { label: "English", key: "en" },
        { label: "العربية", key: "ar" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      settings: "getSettings",
    }),
  },
  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
    }),
    signOut() {
      this.signOutAction().then(() => {
        this.$router.push("/");
      });
    },
    toggleLanguage() {
      const lang = this.$i18n.locale === "en" ? "ar" : "en";

      if (this.$i18n.locale === lang) {
        return;
      }
      window.location.reload();
      localStorage.setItem("language", lang);
    },
    changeLanguage(lang) {
      if (this.$i18n.locale === lang) {
        return;
      }
      window.location.reload();
      localStorage.setItem("language", lang);
    },
  },
  mounted() {
    // when click on any link in offcanvas menu make .btn-close button click
    document.querySelectorAll(".offcanvas a").forEach((el) => {
      el.addEventListener("click", () => {
        document.querySelector(".btn-close").click();
      });
    });
  },
  components: {
    NDropdown,
  },
};
</script>

<style lang="scss" scoped>
header {
  background-color: var(--accent-300);
  padding-bottom: 150px !important;

  .logo {
    max-width: 250px;
    width: 100%;
  }

  nav {
    ul {
      li {
        a {
          color: var(--text-400);
          transition: all 0.3s ease-in-out;

          &:hover {
            color: var(--accent-400);
          }
        }

        .active_menu {
          color: var(--accent-400);
          position: relative;
          font-weight: 600;

          &::before {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            background-color: var(--accent-400);
            position: absolute;
            bottom: -5px;
            opacity: 0.8;
          }
        }
      }
    }
  }

  .btn-primary {
    background-color: var(--accent-400);
    border-color: var(--accent-400);

    &:hover {
      background-color: var(--primary-400);
      border-color: var(--primary-400);
    }
  }

  .offcanvas {
    a {
      color: var(--text-200);
      transition: all 0.3s ease-in-out;

      &:hover {
        color: var(--text-300);
      }
    }

    .active_menu_mob {
      color: var(--text-300) !important;
      font-weight: 600;
    }
  }
}

html[lang="en"] {
  .btn-close {
    margin-right: initial;
  }
}

html[lang="ar"] {
  .btn-close {
    margin-left: 0;
  }
}
</style>
