<template>
  <main class="m-- bg-white pb-3">
    <picture>
      <source
        :srcset="`${imgSrcAvif}?w=1920&h=1080&fm=avif 1920w, ${imgSrcAvif}?w=1080&h=600&fm=avif 1080w, ${imgSrcAvif}?w=600&h=400&fm=avif 600w`"
        sizes="(max-width: 575px) 100vw, (max-width: 767px) 100vw, (max-width: 991px) 100vw, (max-width: 1199px) 100vw, 1920px"
        type="image/avif"
        media="(min-width: 560px) and (min-resolution: 1.5x)"
      />
      <source
        :srcset="`${imgSrc}?w=1920&h=1080&fm=webp 1920w, ${imgSrc}?w=1080&h=600&fm=webp 1080w, ${imgSrc}?w=600&h=400&fm=webp 600w`"
        sizes="(max-width: 575px) 100vw, (max-width: 767px) 100vw, (max-width: 991px) 100vw, (max-width: 1199px) 100vw, 1920px"
        type="image/webp"
        media="(min-width: 560px) and (min-resolution: 1.5x)"
      />
      <img
        :src="`${imgSrc}?w=1920&h=1080&fm=webp`"
        alt="Banner"
        class="img-fluid w-100"
        width="1920"
        height="1080"
        loading="lazy"
        :srcset="`${imgSrc}?w=1920&h=1080&fm=webp 1920w, ${imgSrc}?w=1080&h=600&fm=webp 1080w, ${imgSrc}?w=600&h=400&fm=webp 600w`"
        sizes="(max-width: 575px) 100vw, (max-width: 767px) 100vw, (max-width: 991px) 100vw, (max-width: 1199px) 100vw, 1920px"
      />
    </picture>

    <TitleHome
      :title="$t('Choose a Plan')"
      :subTitle="$t('planDescription')"
      class="wow bounceInUp"
      data-wow-duration="1s"
      data-wow-delay="0.3s"
    />
    <div
      class="vh-100 d-flex justify-content-center align-items-center"
      v-if="isLoadingPlans"
    >
      <div class="spinner-grow spinner-grow-lg m-auto text-primary"></div>
    </div>
    <div v-else>
      <swiper
        :effect="'coverflow'"
        :grabCursor="true"
        :centeredSlides="true"
        :slidesPerView="'auto'"
        :coverflowEffect="{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }"
        :initialSlide="1"
        :pagination="true"
        :modules="modules"
        lazy-preload-prev-next="true"
        lazy-preloader-class="swiper-lazy-preloader-white"
        lazy="true"
        class="mySwiper wow bounceInUp"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
      >
        <swiper-slide v-for="(plan, index) in plans" :key="index">
          <div class="card rounded bg-light border-0 shadow-sm">
            <picture @click="selectPlan(plan.slug)" class="cursor-pointer">
              <source
                :srcset="`${getImagePlan(plan.thumbnail)}?w=300&h=200&fm=avif`"
                type="image/avif"
                media="(min-width: 560px) and (min-resolution: 1.5x)"
                sizes="(max-width: 575px) 100vw, (max-width: 767px) 100vw, (max-width: 991px) 100vw, (max-width: 1199px) 100vw, 300px"
              />
              <source
                :srcset="`${getImagePlan(plan.thumbnail)}?w=300&h=200&fm=webp`"
                type="image/webp"
                media="(min-width: 560px) and (min-resolution: 1.5x)"
                sizes="(max-width: 575px) 100vw, (max-width: 767px) 100vw, (max-width: 991px) 100vw, (max-width: 1199px) 100vw, 300px"
              />
              <img
                :src="getImagePlan(plan.thumbnail)"
                class="object-fit-cover"
                :alt="plan.name"
                height="200px"
                width="300px"
                :title="plan.name"
                loading="lazy"
                :srcset="`${getImagePlan(plan.thumbnail)}?w=300&h=200&fm=webp`"
                sizes="(max-width: 575px) 100vw, (max-width: 767px) 100vw, (max-width: 991px) 100vw, (max-width: 1199px) 100vw, 300px"
              />
            </picture>

            <div class="card-body">
              <div
                class="d-flex text-center m-auto justify-content-center align-items-center mb-3"
              >
                <RouterLink
                  :to="`/plan/${plan.slug}`"
                  :title="plan.name"
                  :aria-label="plan.name"
                  class="fw-bold text-primary text-center m-0"
                >
                  {{ plan.name }}
                </RouterLink>
              </div>
              <div class="d-flex">
                <n-switch
                  v-model:value="plan.showDetails"
                  class="m-auto bg-white rounded-pill"
                  size="large"
                  aria-label="Show Details"
                  title="Show Details"
                >
                  <template #checked>
                    {{ $t("PlanDetails") }}
                  </template>
                  <template #unchecked>
                    {{ $t("showDetails") }}
                  </template>
                </n-switch>
              </div>
              <div class="d-flex gap-3 flex-wrap align-items-center">
                <n-collapse-transition :show="plan.showDetails">
                  <div class="d-flex flex-column">
                    <div v-for="(feature, fIndex) in plan.features" :key="fIndex">
                      <p>
                        <i
                          class="bi"
                          :class="
                            feature.status === 'active'
                              ? 'text-primary bi-check-circle-fill'
                              : 'text-muted bi-x-circle-fill'
                          "
                        ></i>
                        <span
                          class="mx-1"
                          :class="
                            feature.status === 'active'
                              ? 'text-primary'
                              : 'text-muted text-decoration-line-through'
                          "
                        >
                          {{ feature.name }}
                        </span>
                      </p>
                    </div>
                  </div>
                </n-collapse-transition>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="text-center mt-3 my-5" v-if="plans.length === 0">
        <p class="text-muted">
          <i class="bi bi-info-circle"></i>
          {{ $t("No plans found") }}
        </p>
      </div>
    </div>
    <AllPages />
    <hr class="mt-5" />
    <SuccessPartners />
  </main>
</template>

<script>
import axios from "axios";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination } from "swiper/modules";
import { NCollapseTransition, NSwitch } from "naive-ui";

import SuccessPartners from "@/components/Home/SuccessPartners.vue";
import TitleHome from "@/components/TitleHome.vue";
import { pageTitle } from "@/utils/pageTitle";
import AllPages from "@/components/AllPages.vue";

export default {
  name: "HomeView",
  data() {
    return {
      plans: [],
      isLoadingPlans: true,
      imgSrc: "/images/banner_img.webp",
      imgSrcAvif: "/images/banner.avif",
    };
  },
  methods: {
    async getPlans() {
      await axios.get("/plans").then((response) => {
        if (response.status == 200) {
          this.plans = response.data.data[0].map((plan) => ({
            ...plan,
            showDetails: false,
          }));
          this.isLoadingPlans = false;
        }
      });
    },
    selectPlan(name) {
      this.$router.push(`/plan/${name}`);
    },
  },
  computed: {
    getImagePlan() {
      return (thumbnail) => {
        return thumbnail ? thumbnail : "https://placehold.co/600x400";
      };
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    NCollapseTransition,
    NSwitch,
    TitleHome,
    AllPages,
    SuccessPartners,
  },
  mounted() {
    this.getPlans();
    pageTitle(this.$t("Home"));
  },
  setup() {
    return {
      modules: [EffectCoverflow, Pagination],
    };
  },
};
</script>

<style scoped lang="scss">
.swiper {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 50px;
}
.swiper-slide {
  background-position: center;
  background-size: cover;
  max-width: 350px;
  border-radius: 50px;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.m-- {
  margin-top: -140px;
}
.text-primary {
  color: var(--text-300) !important;
}
</style>
