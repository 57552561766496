<template>
  <div class="container">
    <div class="row my-5" v-if="isLoadingPages">
      <div class="spinner-grow spinner-grow-lg m-auto text-primary"></div>
    </div>
    <div class="row" v-else>
      <div class="col-12" v-for="page in pages" :key="page">
        <hr />
        <div
          class="d-flex text-center w-auto justify-content-center align-items-center m-auto flex-column"
        >
          <RouterLink
            :to="'/page/' + page.slug + '/' + page.id"
            :title="page.meta_title"
            :description="page.meta_description"
            :keywords="page.meta_keywords"
            :aria-label="page.title"
            class="wow bounceInUp"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <TitleHome :title="page.title" />
          </RouterLink>
          <n-popover trigger="hover">
            <template #trigger>
              <small
                class="cursor-pointer text-warning wow bounceInUp"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
                @click="$router.push('/page/' + page.slug + '/' + page.id)"
              >
                ({{ $t("Show Page Details") }})
              </small>
            </template>
            <span>
              {{ $t("Click To Go To this is Page") }}
            </span>
          </n-popover>
        </div>
        <div
          v-html="page.description"
          class="mt-3 wow bounceInUp"
          data-wow-duration="1s"
          data-wow-delay="0.3s"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TitleHome from "./TitleHome.vue";
import { NPopover } from "naive-ui";
export default {
  name: "AllPages",
  data() {
    return {
      pages: [],
      isLoadingPages: true,
    };
  },
  mounted() {
    this.getPages();
  },
  methods: {
    async getPages() {
      await axios.get("/pages").then((response) => {
        if (response.status == 200) {
          this.pages = response.data.data[0];
          this.isLoadingPages = false;
        }
      });
    },
  },
  components: {
    TitleHome,
    NPopover,
  },
};
</script>

<style lang="scss" scoped>
.text-primary {
  color: var(--text-300) !important;
}

.text-warning {
  color: var(--accent-400) !important;
}
</style>
