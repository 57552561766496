// theme.js
import { commonLight } from "naive-ui";

export default {
  common: {
    ...commonLight.common,
    primaryColor: "#123B50FF",
    primaryColorHover: "#123B50B8",
    primaryColorPressed: "#0D3043F7",
    primaryColorSuppl: "rgba(27, 71, 99, 1)",
  },
};
