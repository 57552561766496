import toast from "./toastMsg";

const catchError = (e) => {
  const language = localStorage.getItem('language') || 'en'; // Default to 'en' if language is not set

  if (e.response.status === 404) {
    const errorMessage = language === 'ar' ? 'المستخدم غير موجود' : 'User Not Found';
    toast.errorToast(errorMessage);
  } else if (e.response.status === 401) {
    toast.errorToast(language === 'ar' ?  'البريد الإلكترونى أو كلمة المرور غير صحيحة' : 'Email or Password is incorrect');
  } else if (e.response.status === 403) {
    toast.errorToast(language === 'ar' ? 'غير مسموح' : 'Access Forbidden');
  } else {
    toast.errorToast(language === 'ar' ? 'حدث خطأ ما' : 'Something went wrong');
  }
};

export default catchError;
