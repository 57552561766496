<template>
  <div class="d-flex flex-column">
    <h6
      class="text-white text-center wow bounceInUp"
      data-wow-duration="1s"
      data-wow-delay="0.3s"
    >
      {{ $t("We Will Are Happy To Join To Our Network") }}
    </h6>
    <div
      class="m-auto d-flex justify-content-center align-items-center w-auto text-center my-2"
    >
      <RouterLink
        to="/our-network"
        class="text-decoration-none wow bounceInUp"
        data-wow-duration="1s"
        data-wow-delay="0.1s"
        :title="$t('JoinNow')"
        :aria-label="$t('JoinNow')"
      >
        <button type="button" class="btn btn-primary px-5" name="JoinNow" title="JoinNow">
          <!-- icon -->
          <i class="bi bi-people-fill fs-5"></i>
          {{ $t("JoinNow") }}
        </button>
      </RouterLink>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubscribeSection",
};
</script>

<style lang="scss" scoped>
.btn-primary {
  background-color: var(--accent-400) !important;
  border-color: var(--accent-400) !important;
}
</style>
