import store from "@/Store";

const checkLogin = (to, from, next) => {
  if (store.getters["auth/authenticated"]) {
    next("/");
  } else {
    next();
  }
};

export default checkLogin;
