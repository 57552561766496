import toastMsg from "@/utils/toastMsg";
import axios from "axios";

export default {
  state: {
    Settings: {},
    loader: true,
  },
  mutations: {
    setSettings(state, payload) {
      state.Settings = payload;
    },
  },
  actions: {
    async getSettings({ commit, state }) {
      await axios
        .get("/general/settings")
        .then((response) => {
          if (response.status === 200) {
            commit("setSettings", response.data.data[0]);
             window.sessionStorage.setItem("general_settings", JSON.stringify(response.data.data[0]));
            state.loader = false;
          }
        })
        .catch(() => {
          toastMsg.errorToast("Failed to fetch settings");
        });
    },
  },
  getters: {
    getSettings: (state) => state.Settings,
  },
};
