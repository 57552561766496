<template>
  <NConfigProvider :theme-overrides="customTheme">
    <HeaderTop />
    <BtnTools />
    <div class="min-vh-80">
      <RouterView />
    </div>
    <FooterHome />
  </NConfigProvider>
</template>

<script>
import FooterHome from "./components/FooterHome.vue";
import HeaderTop from "./components/HeaderTop.vue";
import { NConfigProvider } from "naive-ui";
import customTheme from "./theme";
import BtnTools from "./components/BtnTools.vue";

export default {
  name: "App",
  components: {
    HeaderTop,
    FooterHome,
    NConfigProvider,
    BtnTools,
  },
  data() {
    return {
      customTheme,
    };
  },
};
</script>

<style scoped>
.min-vh-80 {
  min-height: 80vh;
}
</style>
