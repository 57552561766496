<template>
  <div
    class="d-flex flex-column gap-2 position-absolute bottom-50 position-fixed me-3 icons_aside"
  >
    <n-popover trigger="hover" :placement="$i18n.locale == 'ar' ? 'left' : 'right'">
      <template #trigger>
        <a
          :href="'https://wa.me/' + general_settings.whatsapp"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="whatsapp"
          :title="$t('whatsapp')"
        >
          <button
            type="button"
            class="btn btn-primary shadow-sm d-flex align-items-center justify-content-center z-1"
            title="whatsapp"
            name="whatsapp"
          >
            <i class="bi bi-whatsapp"></i>
          </button>
        </a>
      </template>
      <span>
        {{ $t("whatsapp") }}
      </span>
    </n-popover>
    <n-popover trigger="hover" :placement="$i18n.locale == 'ar' ? 'left' : 'right'">
      <template #trigger>
        <button
          type="button"
          @click="$router.push('/Subscribe')"
          class="btn btn-primary shadow-sm d-flex align-items-center justify-content-center z-1"
          name="subscribe"
          title="subscribe"
        >
          <i class="bi bi-envelope"></i>
        </button>
      </template>
      <span>
        {{ $t("Subscribe") }}
      </span>
    </n-popover>
    <n-popover trigger="hover" :placement="$i18n.locale == 'ar' ? 'left' : 'right'">
      <template #trigger>
        <button
          type="button"
          class="btn btn-primary shadow-sm d-flex align-items-center justify-content-center z-1 btn_to_top"
          @click="toTop"
          title="UpToTop"
          name="UpToTop"
        >
          <i class="bi bi-arrow-up-short"></i>
        </button>
      </template>
      <span>
        {{ $t("UpToTop") }}
      </span>
    </n-popover>
  </div>
</template>

<script>
import{mapGetters as e}from"vuex";import t from"./Home/PackagesThree.vue";import{NPopover as s}from"naive-ui";export default{name:"BtnTools",data:()=>({general_settings:{}}),components:{PackagesThree:t,NPopover:s},methods:{toTop(){window.scrollTo({top:0,behavior:"smooth"})}},computed:{...e({settings:"getSettings"})},mounted(){window.addEventListener("scroll",()=>{let e=document.querySelector(".icons_aside");window.scrollY>10?e.classList.add("icons_aside_effect"):e.classList.remove("icons_aside_effect")}),sessionStorage.getItem("general_settings")?this.general_settings=JSON.parse(sessionStorage.getItem("general_settings")):this.$store.dispatch("getSettings")}};
</script>

<style scoped lang="scss">
.btn-primary {
  background-color: var(--accent-400);
  border-color: var(--accent-400);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 0 0.2rem rgba(248, 202, 163, 0.5) !important;

  &:hover {
    background-color: var(--accent-300);
    border-color: var(--accent-300);
    box-shadow: 0 0 0 0.2rem rgba(121, 128, 252, 0.5) !important;
  }
}

.btn_to_top {
  background-color: var(--text-300);
  border-color: var(--text-300);
  box-shadow: 0 0 0 0.2rem rgba(0, 37, 77, 0.5) !important;

  &:hover {
    box-shadow: 0 0 0 0.2rem rgba(0, 37, 80, 0.8) !important;
  }
}

.bottom-50 {
  bottom: 50px !important;
}

html[lang="ar"] {
  .icons_aside {
    z-index: 9 !important;
    transform: translateX(100px);
    transition: all 0.5s ease-in-out;
  }

  .icons_aside_effect {
    transform: translateX(0);
  }
}

html[lang="en"] {
  .icons_aside {
    z-index: 9 !important;
    transform: translateX(-100px);
    transition: all 0.5s ease-in-out;
  }

  .icons_aside_effect {
    transform: translateX(20px);
  }
}
</style>
