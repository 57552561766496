import e from "axios";
import a from "../utils/toastMsg";
import t from "@/utils/catch";
import s from "../utils/toastMsg";
export default {
  namespaced: !0,
  state: { token: null, user: null },
  getters: { authenticated: (e) => e.token && e.user, user: (e) => e.user },
  mutations: {
    SET_TOKEN(e, a) {
      e.token = a;
    },
    SET_USER(e, a) {
      e.user = a;
    },
  },
  actions: {
    async signIn({ dispatch: r }, o) {
      let n = await e
        .post("/user/auth/login", o, {
          headers: {
            "Accept-Language": localStorage.getItem("language") || "en",
          },
        })
        .then((e) => e)
        .catch((e) => {
          if (401 == e.response.data.code) {
            let r = localStorage.getItem("language") || "en";
            a.errorToast(
              "ar" === r
                ? "البريد الإلكترونى أو كلمة المرور غير صحيحة"
                : "Email or Password is Incorrect"
            );
          } else if (e.response.data.data.email_verified_at) {
            let n = localStorage.getItem("language") || "en",
              l =
                "ar" === n
                  ? e.response.data.data.email_verified_at[0]
                  : "Please Verify Email Address";
            s.errorToast(l),
              router.push({ name: "VerifyEmail", query: { email: o.email } });
          } else t(e);
        });
      r("attempt", n.data.data.token);
    },
    async register({ dispatch: s }, r) {
      let o = (
        await e
          .post("/user/auth/register", r)
          .then((e) => {
            let t = localStorage.getItem("language") || "en";
            return (
              a.successToast(
                "ar" === t
                  ? "يرجى التحقق من البريد الإلكتروني بعد التسجيل بنجاح"
                  : "Please, Verify Email Message After Register Successfully"
              ),
              e
            );
          })
          .catch((e) => {
            e.response.data.data.email
              ? a.errorToast(e.response.data.data.email[0])
              : e.response.data.data.phone
              ? a.errorToast(e.response.data.data.phone[0])
              : e.response.data.data.dialcode
              ? a.errorToast(e.response.data.data.dialcode[0])
              : e.response.data.data.country_code
              ? a.errorToast(e.response.data.data.country_code[0])
              : t(e);
          })
      ).data.data.token;
      s("attempt", o);
    },
    async attempt({ commit: a, state: t }, s) {
      if ((s && a("SET_TOKEN", s), t.token))
        try {
          let r = await e.get("/user/profile/fetch", {
            headers: {
              "Accept-Language": localStorage.getItem("language") || "en",
            },
          });
          a("SET_USER", r.data.data.user);
        } catch (o) {
          a("SET_TOKEN", null), a("SET_USER", null);
        }
    },
    signOut: ({ commit: s, state: r }) =>
      e
        .post("user/profile/logout")
        .then((e) => {
          if (200 == e.status) {
            let t = localStorage.getItem("language") || "en",
              r = "ar" === t ? "تم تسجيل الخروج بنجاح" : "Log out Successfully";
            a.successToast(r);
          }
          s("SET_TOKEN", null), s("SET_USER", null);
        })
        .then((e) => e)
        .catch((e) => {
          t(e);
        }),
  },
};
