import store from "@/Store";

const checkUser = (to, from, next) => {
  if (store.getters["auth/authenticated"]) {
    next();
  } else {
    next("/login");
  }
};

export default checkUser;
